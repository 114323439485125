<template>
  <div
    v-if="!web3Address"
    class="container mx-auto p-4"
  >
    <div class="text-center w-3/4 mx-auto">
      <p
        class="sm:text-1xl md:text-2xl lg:text-3xl font-semibold text-red-500 mb-4"
      >
        Wallet Not Connected
      </p>
      <p class="sm:text-sm md:text-md lg:text-lg text-gray-300 mb-4">
        Please connect your wallet to continue.
      </p>
    </div>
  </div>
  <div
    v-else-if="isTransitioningToFundingState"
    class="container mx-auto p-4"
  >
    <div class="text-center w-3/4 mx-auto">
      <h2 class="text-3xl font-semibold text-yellow-500 mb-4">
        Claim Period Ended
      </h2>
      <p class="text-lg text-gray-300 mb-4">
        Please wait while Smart Contract prepares the next round.
      </p>
    </div>
  </div>
  <div v-else>
    <div
      v-if="
        (gameState === 0 || gameState === 2 || gamesLeft === 0) &&
          !isTransitioningToClaimTime
      "
      class="container mx-auto !p-4"
    >
      <div
        v-if="gameState === 0"
        class="mx-auto px-4 py-6 bg-black bg-opacity-75 "
      >
        <div class="text-center w-3/4 mx-auto">
          <h2
            class="text-3xl font-semibold mb-4"
            :class="{
              'text-yellow-500': Number(prizePool) < 100,
              'text-green-500': Number(prizePool) >= 100,
            }"
          >
            {{
              Number(prizePool) >= 100
                ? "Funding Goal Reached! 🎉"
                : "Prize Pool Funding"
            }}
          </h2>

          <!-- Progress Bar -->
          <div class="mb-8">
            <div class="flex justify-between text-sm mb-2">
              <span class="text-gray-400">Current Progress</span>
              <span
                class="font-bold"
                :class="{
                  'text-gray-400': Number(prizePool) < 100,
                  'text-green-400 animate-pulse': Number(prizePool) >= 100,
                }"
              >
                {{ Number(prizePool).toFixed(2) }}%
                <span v-if="Number(prizePool) >= 100">🚀</span>
              </span>
            </div>

            <div
              class="h-6 bg-gray-800 rounded-full overflow-hidden relative border border-gray-700"
            >
              <!-- Base progress bar -->
              <div
                class="h-full transition-all duration-500 relative"
                :class="{
                  'bg-gradient-to-r from-yellow-600 to-yellow-400':
                    Number(prizePool) < 100,
                  'bg-gradient-to-r from-green-600 to-green-400 animate-pulse':
                    Number(prizePool) >= 100,
                }"
                :style="{ width: `${Math.min(Number(prizePool), 100)}%` }"
              >
                <!-- Shimmer effect -->
                <div
                  class="absolute inset-0 bg-gradient-to-r from-transparent via-white to-transparent opacity-10 animate-shimmer-horizontal"
                />
              </div>
            </div>

            <!-- Prize Pool Display -->
            <div
              class="mt-6 bg-gray-800 bg-opacity-75 rounded-xl p-6 border border-gray-700"
            >
              <h3 class="text-xl font-semibold text-gray-300 mb-2">
                Current Prize Pool
              </h3>
              <div
                class="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-yellow-200"
              >
                {{ Number(prizePool).toFixed(2) }} $FLAPPY
              </div>
              <span class="text-green-400 font-semibold">(${{
                (Number(prizePool) * Number(flappyPrice)).toFixed(2)
              }})</span>

              <div class="text-sm text-white mt-2">
                Minimum: 100 $FLAPPY
              </div>
            </div>

            <!-- Action Section -->
            <div class="mt-8">
              <template v-if="Number(prizePool) >= 100">
                <p class="text-lg text-green-400 mb-4 animate-pulse">
                  Get ready! Game starts soon...
                </p>
              </template>
              <template v-else>
                <p class="text-lg text-gray-300 mb-4">
                  Join the action! Contribute to start the next round
                </p>
                <DepositComponent />
              </template>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="gameState === 2"
        class="text-center w-3/4 mx-auto claim-container"
      >
        <!-- Show claimed screen if hasClaimed, regardless of current allocation -->
        <template v-if="hasClaimed">
          <ClaimSuccessAnimation
            v-if="showSuccessAnimation"
            @close="showSuccessAnimation = false"
          />
          <div class="claimed-badge">
            <span class="checkmark">✓</span>
            <span class="text">Successfully Claimed!</span>
            <span class="sparkles">✨</span>
          </div>
          <p class="mt-4 text-sm text-gray-400">
            Screen will update when claim period ends
          </p>
        </template>

        <!-- Only check allocation for unclaimed users -->
        <template v-else>
          <!-- Show missed submission message if applicable -->
          <template v-if="lastRoundScore > 0 && !scoreSubmitted">
            <h2 class="claim-header">
              Missed wallet verification! 🚫
            </h2>

            <div class="score-display mb-8 with-particles">
              <div class="score-section mb-6">
                <span class="text-lg text-gray-300">Your Last Round Score:</span>
                <div class="score-value">
                  <span
                    class="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-orange-400 to-red-400"
                  >
                    {{ lastRoundScore }}
                  </span>
                </div>
              </div>

              <div class="motivation-box">
                <p class="text-lg text-red-400 mb-3">
                  We detected a score from your last round, but you forgot to
                  submit it.
                </p>
                <p class="text-lg text-gray-300">
                  Score submission is required to receive prize fund allocation.
                </p>
              </div>
            </div>
          </template>
          <!-- Existing claim screen content -->
          <template v-else>
            <template v-if="allocation > 0">
              <h2 class="claim-header animate-bounce-slow">
                🎉 Congratulations! 🎉
              </h2>

              <div class="score-display mb-8 prize-glow">
                <div class="prize-banner mb-6">
                  <span
                    class="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-yellow-300 via-yellow-400 to-yellow-500"
                  >
                    You've Won
                  </span>
                </div>

                <div class="prize-amount-display mb-6">
                  <span
                    class="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-400"
                  >
                    {{ Number(allocation).toFixed(2) }}
                  </span>
                  <span class="text-2xl text-purple-300">$FLAPPY</span>
                </div>

                <button
                  class="claim-button group relative"
                  @click="claimAllocation"
                >
                  <span class="relative z-10">Claim Your Prize</span>
                  <div
                    class="absolute inset-0 bg-gradient-to-r from-purple-600 to-pink-600 rounded-lg blur opacity-40 group-hover:opacity-75 transition-opacity"
                  />
                </button>
              </div>
            </template>
            <template v-else>
              <!-- No allocation content -->
              <h2 class="claim-header">
                Round Complete! 🎮
              </h2>

              <div class="score-display mb-8 with-particles">
                <div class="score-section mb-6">
                  <span class="text-lg text-gray-300">Your Best Score:</span>
                  <div class="score-value animate-pulse">
                    <span
                      class="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-orange-400 to-red-400"
                    >
                      {{ lastRoundScore }}
                    </span>
                  </div>
                </div>

                <div class="motivation-box">
                  <p class="text-lg text-gray-300 mb-3">
                    So close! You're showing real potential! 💪
                  </p>
                  <p
                    class="text-lg text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-orange-400 font-bold"
                  >
                    Next round's victory could be yours! Ready for another shot?
                    🚀
                  </p>
                </div>
              </div>
            </template>
          </template>
        </template>

        <!-- Common elements -->
        <div class="mt-8 w-3/4 text-center mx-auto stats-container">
          <StatsDisplay />
        </div>
      </div>
      <div
        v-if="gameState == 1 && (gamesLeft === 0 || remainingGameTime < 60)"
        class="text-center lg:w-3/4 md:w-3/4 sm:w-full mx-auto px-4"
      >
        <h2 class="text-4xl font-bold text-white mb-4">
          Game Over!
        </h2>
        <p class="text-xl text-custom-orange mb-8 font-semibold">
          Your best score: {{ highScore }} points
        </p>

        <div class="bg-gray-800 p-6 rounded-xl shadow-lg w-4/4 mx-auto">
          <div class="space-y-6">
            <!-- Submit Score Button -->
            <div>
              <SignMessageButton />
              <p class="text-sm text-gray-400 mt-2">
                Note: Once wallet is verified new high scores will be submitted automatically.
              </p>
            </div>

            <!-- OR Divider -->
            <div class="flex items-center justify-center w-full">
              <div class="flex-grow border-t border-gray-600" />
              <span class="px-4 text-gray-400">OR</span>
              <div class="flex-grow border-t border-gray-600" />
            </div>

            <!-- Buy More Games Button -->
            <div class="relative">
              <button
                class="font-bold py-3 px-6 rounded-lg transition duration-300"
                :class="{
                  'bg-gradient-to-r from-green-500 to-green-600 text-white hover:scale-105 hover:from-green-600 hover:to-green-700 animate-pulse':
                    remainingGameTime >= 300,
                  'bg-gray-600 text-gray-400 cursor-not-allowed':
                    remainingGameTime < 300,
                }"
                :title="getButtonTitle"
                @click="
                  remainingGameTime >= 300
                    ? (showBuyGamesModal = true)
                    : null
                "
              >
                Donate & Get More Games 🎮
              </button>
              <div
                v-if="remainingGameTime < 300 || scoreSubmitted"
                class="absolute -top-12 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-sm py-2 px-4 rounded shadow-lg whitespace-nowrap"
              >
                {{ getButtonTitle }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Buy Games Modal -->
      <div
        v-if="showBuyGamesModal"
        class="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 px-4"
        @click.self="showBuyGamesModal = false"
      >
        <div class="bg-gray-800 rounded-xl p-8 max-w-md w-full">
          <!-- Modal header -->
          <div class="flex justify-between items-center mb-6">
            <h3 class="text-2xl font-bold text-white">
              Donate & get more games
            </h3>
            <button
              class="text-gray-400 hover:text-white"
              @click="showBuyGamesModal = false"
            >
              ×
            </button>
          </div>

          <!-- Conditional Message -->
          <div v-if="cannotAffordAnyPackage" class="text-center mb-6 p-4 bg-red-900/50 border border-red-500 rounded-lg">
            <p class="font-bold text-red-400 text-lg mb-2">Insufficient $FLAPPY Balance</p>
            <p class="text-sm text-gray-300 mb-3">You need more $FLAPPY tokens to donate for any game package.</p>
            <a :href="xyFinanceLink" target="_blank" rel="noopener noreferrer" class="inline-block bg-yellow-500 text-black font-bold py-2 px-4 rounded hover:bg-yellow-600 text-sm">
              Buy $FLAPPY Now
            </a>
          </div>

          <!-- Game packages with overlay -->
          <div class="relative">
            <!-- Game Packages -->
            <div class="space-y-4">
              <div
                v-for="(gamePack, index) in gamePackages"
                :key="index"
                class="bg-gray-700 rounded-lg p-4 hover:bg-gray-600 transition-all"
                :class="{ 
                  relative: gamePack.bestValue,
                  'opacity-50': cannotAffordAnyPackage, 
                  'cursor-pointer': userBalance >= gamePack.price
                }"
                @click="userBalance >= gamePack.price ? buyGames(gamePack.games) : null"
              >
                <div
                  v-if="gamePack.bestValue"
                  class="absolute -top-3 left-1/2 transform -translate-x-1/2"
                >
                  <span
                    class="bg-yellow-500 text-black text-sm font-bold px-3 py-1 rounded-full"
                  >
                    BEST VALUE
                  </span>
                </div>
                <div class="flex justify-between items-center">
                  <div>
                    <h4 class="text-lg font-bold text-white">
                      {{ gamePack.games }} Games
                    </h4>
                    <p class="text-green-400 font-bold">
                      {{ gamePack.price }} $Flappy
                    </p>
                    <!-- Changed Button to Link -->
                    <div v-if="userBalance < gamePack.price && !cannotAffordAnyPackage" class="mt-2">
                      <a 
                         :href="xyFinanceLink"
                         target="_blank" 
                         rel="noopener noreferrer"
                         class="text-xs text-yellow-400 hover:text-yellow-300 underline"
                       >
                         Not enough $FLAPPY? Buy More
                       </a>
                    </div>
                  </div>
                  <button
                    class="bg-white text-green-700 hover:bg-gray-100 font-bold py-2 px-4 rounded-lg transition duration-300"
                    :disabled="userBalance < gamePack.price" 
                    :class="{ 'opacity-50 cursor-not-allowed': userBalance < gamePack.price }"
                    @click.stop="buyGames(gamePack.games)"
                  >
                    Get
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isTransitioningToClaimTime"
      class="container mx-auto p-4"
    >
      <div class="text-center w-3/4 mx-auto">
        <h2 class="text-3xl font-semibold text-yellow-500 mb-4">
          Finalizing Round...
        </h2>
        <p class="text-lg text-gray-300 mb-4">
          Please wait while we submit the final scores to the blockchain and
          calculate the rewards.
        </p>
      </div>
    </div>
    <div
      v-if="
        isGameVisible &&
          gamesLeft != 0 &&
          gamesLeft != null &&
          gameState === 1 &&
          !isTransitioningToClaimTime
      "
      id="gamecontainer"
      class="game-container lg:block "
      tabindex="0"
    >
      <div id="gamescreen">
        <div
          id="sky"
          class="animated"
        >
          <div id="flyarea">
            <div
              id="ceiling"
              class="animated"
            />
            <div
              id="player"
              class="bird animated"
            />
            <div id="bigscore" />
            <div id="splash">
              <!-- Centered container for splash content -->
              <div class="flex flex-col items-center justify-center w-full max-w-lg mx-auto p-6">
                <h2 class="text-2xl font-bold text-white mb-4 uppercase tracking-wider">
                  Get Ready
                </h2>
                <h3 class="text-base text-gray-300 text-center mb-8 uppercase tracking-wide">
                  Try to pass as many obstacles as possible to reach the Top 5
                </h3>
                <!-- List container -->
                <div class="w-full text-left mb-8 px-4">
                  <ul class="list-none space-y-3 text-gray-200 text-sm">
                    <li class="flex items-start">
                      <span class="text-orange-400 mr-2">></span>
                      <span>You have 10 games in total ({{ gamesLeft }} left)</span>
                    </li>
                    <li class="flex items-start">
                      <span class="text-orange-400 mr-2">></span>
                      <span>Highest score can be submitted after game round is over (currently {{ highScore }})</span>
                    </li>
                    <li class="flex items-start">
                      <span class="text-orange-400 mr-2">></span>
                      <span>Top 5 players share the prize pool</span>
                    </li>
                  </ul>
                </div>
                <!-- Button container -->
                <div class="w-full text-center">
                  <button 
                    class="inline-block px-6 py-2 bg-orange-500 text-white text-sm font-semibold rounded hover:bg-orange-600 transition-colors duration-200 uppercase tracking-wider"
                    @click="startGame"
                  >
                    Click here to start! LFG!
                  </button>
                </div>
              </div>
            </div>
            <div id="scoreboard">
              <div id="currentscore" />
              <div id="highscore" />
              <div id="replay" />
            </div>
          </div>
        </div>
        <div
          id="land"
          class="animated"
        >
          <div id="debug" />
        </div>
        <div
          id="playerbox"
          class="boundingbox"
        />
        <div
          id="pipebox"
          class="boundingbox"
        />
      </div>
    </div>
    <div
      v-else-if="gamesLeft == null"
      class="text-center w-4/4 mx-auto"
    >
      <h2
        class="sm:text-2xl md:text-3xl lg:text-3xl font-semibold text-red-500 mb-4"
      >
        Something is wrong here, mate!
      </h2>
    </div>
  </div>
  <div
    v-if="DEBUG_MODE"
    class="fixed bottom-4 right-4 z-[9999] space-y-2 bg-gray-800 p-4 rounded-lg shadow-lg max-w-[300px]"
  >
    <div
      class="flex justify-between items-center cursor-pointer"
      @click="isDebugPanelOpen = !isDebugPanelOpen"
    >
      <div class="text-white text-sm font-bold">
        Debug Panel
      </div>
      <div class="text-white text-sm">
        {{ isDebugPanelOpen ? "▼" : "▲" }}
      </div>
    </div>

    <div
      v-if="isDebugPanelOpen"
      class="space-y-2"
    >
      <!-- State Overview -->
      <div class="bg-gray-700 p-2 rounded text-xs space-y-1">
        <div class="text-white font-bold mb-1">
          Current State:
        </div>
        <div class="text-gray-300">
          Web3 Address: {{ web3Address ? "✅" : "❌" }}
        </div>
        <div class="text-gray-300">
          Game State: {{ gameState }}
        </div>
        <div class="text-gray-300">
          Games Left: {{ gamesLeft }}
        </div>
        <div class="text-gray-300">
          Prize Pool: {{ prizePool }}
        </div>
        <div class="text-gray-300">
          Allocation: {{ allocation }}
        </div>
        <div class="text-gray-300">
          Has Claimed: {{ hasClaimed ? "✅" : "❌" }}
        </div>
        <div class="text-gray-300">
          Transitions:
        </div>
        <div class="ml-2 text-gray-300">
          To Funding: {{ isTransitioningToFundingState ? "✅" : "❌" }}
        </div>
        <div class="ml-2 text-gray-300">
          To Claim: {{ isTransitioningToClaimTime ? "✅" : "❌" }}
        </div>
        <div class="text-gray-300">
          Last Round Score: {{ lastRoundScore }}
        </div>
      </div>

      <!-- Game State Controls -->
      <div class="space-y-2">
        <div class="text-white text-xs font-bold">
          Game State
        </div>
        <div class="grid grid-cols-3 gap-1">
          <button
            class="bg-yellow-500 hover:bg-yellow-600 text-white px-2 py-1 rounded text-xs"
            :class="{ 'ring-2 ring-white': gameState === 0 }"
            @click="debugSetGameState(0)"
          >
            Funding (0)
          </button>
          <button
            class="bg-blue-500 hover:bg-blue-600 text-white px-2 py-1 rounded text-xs"
            :class="{ 'ring-2 ring-white': gameState === 1 }"
            @click="debugSetGameState(1)"
          >
            Playing (1)
          </button>
          <button
            class="bg-green-500 hover:bg-green-600 text-white px-2 py-1 rounded text-xs"
            :class="{ 'ring-2 ring-white': gameState === 2 }"
            @click="debugSetGameState(2)"
          >
            Claim (2)
          </button>
        </div>
      </div>

      <!-- Games Left Control -->
      <div class="space-y-1">
        <div class="text-white text-xs font-bold">
          Games Left
        </div>
        <div class="flex space-x-1">
          <input
            v-model="debugGamesLeft"
            type="number"
            class="w-2/3 bg-gray-700 text-white px-2 py-1 rounded text-xs"
            placeholder="Number of games"
          >
          <button
            class="w-1/3 bg-blue-500 hover:bg-blue-600 text-white px-2 py-1 rounded text-xs"
            @click="debugSetGamesLeft"
          >
            Set
          </button>
        </div>
      </div>

      <!-- Prize Pool & Allocation Controls -->
      <div class="grid grid-cols-2 gap-2">
        <div class="space-y-1">
          <div class="text-white text-xs font-bold">
            Prize Pool
          </div>
          <div class="flex space-x-1">
            <input
              v-model="debugPrizePool"
              type="number"
              class="w-2/3 bg-gray-700 text-white px-2 py-1 rounded text-xs"
              placeholder="Amount"
            >
            <button
              class="w-1/3 bg-yellow-500 hover:bg-yellow-600 text-white px-2 py-1 rounded text-xs"
              @click="debugSetPrizePool(debugPrizePool)"
            >
              Set
            </button>
          </div>
        </div>
        <div class="space-y-1">
          <div class="text-white text-xs font-bold">
            Allocation
          </div>
          <div class="flex space-x-1">
            <input
              v-model="debugAllocation"
              type="number"
              class="w-2/3 bg-gray-700 text-white px-2 py-1 rounded text-xs"
              placeholder="Amount"
            >
            <button
              class="w-1/3 bg-purple-500 hover:bg-purple-600 text-white px-2 py-1 rounded text-xs"
              @click="debugSetAllocation"
            >
              Set
            </button>
          </div>
        </div>
      </div>

      <!-- Transition Controls -->
      <div class="space-y-2">
        <div class="text-white text-xs font-bold">
          Transitions
        </div>
        <div class="grid grid-cols-2 gap-1">
          <button
            class="bg-orange-500 hover:bg-orange-600 text-white px-2 py-1 rounded text-xs"
            @click="debugSetTransitionState('funding')"
          >
            To Funding
          </button>
          <button
            class="bg-orange-500 hover:bg-orange-600 text-white px-2 py-1 rounded text-xs"
            @click="debugSetTransitionState('claim')"
          >
            To Claim
          </button>
        </div>
      </div>

      <!-- Add Last Round Score Control -->
      <div class="space-y-1">
        <div class="text-white text-xs font-bold">
          Last Round Score
        </div>
        <div class="flex space-x-1">
          <input
            v-model="debugLastRoundScore"
            type="number"
            class="w-2/3 bg-gray-700 text-white px-2 py-1 rounded text-xs"
            placeholder="Set score"
          >
          <button
            class="w-1/3 bg-orange-500 hover:bg-orange-600 text-white px-2 py-1 rounded text-xs"
            @click="debugSetLastRoundScore"
          >
            Set
          </button>
        </div>
      </div>

      <!-- Reset Button -->
      <button
        class="w-full bg-red-500 hover:bg-red-600 text-white px-2 py-1 rounded text-xs mt-4"
        @click="debugResetAll"
      >
        Reset All States
      </button>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import apiService from "../assets/js/apiService.js";
import SignMessageButton from "./SignMessageButton.vue";
import { connect } from "@kolirt/vue-web3-auth";
import DepositComponent from "./DepositComponent.vue";
import { useToast } from "vue-toastification";
import StatsDisplay from "./StatsDisplay.vue";
import { mapState } from "vuex";
import ClaimSuccessAnimation from "./ClaimSuccessAnimation.vue";
import { pollTransactionReceipt } from "../utils/alchemy";

// Duration constants with validation
const TRANSITION_DURATION = Number(process.env.VUE_APP_TRANSITION_DURATION);

const DEBUG_MODE = process.env.VUE_APP_ENV === "local";

if (DEBUG_MODE) {
  console.log("Running in local environment with debug features enabled");
}

// Define the XY Finance link URL (same as in AppNavigation)
const xyFinanceLink = 'https://app.xy.finance?sourceChainId=8453&targetChainId=8453&fromTokenAddress=0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE&toTokenAddress=0x3b84ACaAeA0d730564312C7eaA39Fef2c4983737&amount=0.007175';

export default {
  name: "GameComponent",
  components: {
    SignMessageButton,
    DepositComponent,
    StatsDisplay,
    ClaimSuccessAnimation,
  },
  setup() {
    const store = useStore();
    const isGameVisible = computed(() => store.state.isGameVisible);
    const gamesLeft = computed(() => store.state.gamesLeft);
    const highScore = computed(() => store.state.highestScore);
    const gameState = computed(() => store.state.gameState);
    const prizePool = computed(() => store.state.prizePool);
    const web3Address = computed(() => store.state.web3Address);
    const allocation = computed(() => store.state.allocation);
    const isInitialized = computed(() => store.state.isInitialized);
    const alchemyUrl = computed(() => store.state.alchemyUrl);

    const countdown = ref(0);
    const isScriptLoaded = computed(() => store.state.gameScriptsLoaded);
    const toast = useToast();
    const claimAllocation = async () => {
      try {
        if (!store.state.GameContract.methods.claimAllocation) {
          console.error("Contract method 'claimAllocation' not found.");
          toast.error("Contract method not found. Please try again later.");
          return;
        }

        // 1. Prepare transaction parameters
        const txParams = {
          from: web3Address.value,
          to: store.state.GameContract.options.address,
          data: store.state.GameContract.methods.claimAllocation().encodeABI(),
        };

        console.log("🔧 Transaction parameters prepared:", txParams);
        toast.info("Please confirm the claim in your wallet...");

        // 2. Send transaction
        const txHash = await window.ethereum.request({
          method: "eth_sendTransaction",
          params: [txParams],
        });

        console.log("📨 Transaction sent, waiting for confirmation:", {
          txHash,
        });

        // 3. Poll for receipt using Alchemy
        const receipt = await pollTransactionReceipt(txHash, alchemyUrl.value);

        if (!receipt || !receipt.status) {
          console.error("❌ Transaction failed or receipt invalid:", {
            receipt,
          });
          throw new Error("Transaction failed");
        }

        console.log("🎉 Claim transaction confirmed:", { receipt });
        store.commit("SET_HAS_CLAIMED", true);
        toast.success("Successfully claimed your allocation! 🎉");

        await Promise.all([
          store.dispatch("fetchAllocation"),
          store.dispatch("fetchUserBalance"),
          store.dispatch("fetchGameState"),
        ]);
      } catch (error) {
        console.error("Failed to claim allocation:", error);
        if (error.message?.includes("User denied")) {
          toast.error("Transaction was cancelled.");
        } else {
          toast.error("Failed to claim allocation. Please try again.");
        }
      }
    };

    const isTransitioningToClaimTime = computed(
      () => store.state.isTransitioningToClaimTime
    );

    // Add new refs for funding state
    const isFundingComplete = computed(() => Number(prizePool.value) >= 100);

    // Add computed property for funding progress percentage
    const fundingProgressPercent = computed(() => {
      return Math.min((Number(prizePool.value) / 10) * 100, 100);
    });

    // Get user balance from store and parse it
    const userBalance = computed(() => parseFloat(store.state.userBalance || '0'));

    const isGameActive = computed(() => store.state.isGameActive);

    return {
      isGameVisible,
      gamesLeft,
      highScore,
      gameState,
      web3Address,
      prizePool,
      allocation,
      countdown,
      isInitialized,
      claimAllocation,
      isScriptLoaded,
      isTransitioningToClaimTime,
      isFundingComplete,
      fundingProgressPercent,
      remainingTime: computed(() => store.state.remainingTime),
      DEBUG_MODE,
      toast,
      userBalance,
      isGameActive,
      gamePackages: [
        { games: 10, price: "200", bestValue: false },
        { games: 30, price: "500", bestValue: true },
        { games: 75, price: "1250", bestValue: false },
      ],
      transitionCountdownTimer: null,
      debugGamesLeft: "",
      debugPrizePool: "",
      debugAllocation: "",
      isDebugPanelOpen: true,
      showSuccessAnimation: true,
      debugLastRoundScore: "",
      xyFinanceLink,
    };
  },
  data() {
    return {
      gameStartedHandler: null,
      showBuyGamesModal: false, // This controls the original modal with game packages
    };
  },
  watch: {
    isInitialized() {
      this.checkGameInitialization();
    },
    prizePool: {
      handler(newValue) {
        if (Number(newValue) >= 100 && this.gameState === 0) {
          this.$store.commit("SET_TRANSITION_COUNTDOWN", TRANSITION_DURATION);
          this.$store.dispatch("startCountdown", {
            type: "fundingPeriod",
            duration: TRANSITION_DURATION,
          });
        }
      },
      immediate: true,
    },
    gamesLeft: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue && newValue > 0) {
          // this.initializeGameInstance();
        }
      },
    },
  },
  async mounted() {
    try {
      this.checkGameInitialization();

      this.gameStartedHandler = (event) => {
        event.stopPropagation();
        this.handleGameStarted(event);
      };
      window.addEventListener("gameStarted", this.gameStartedHandler);
      window.addEventListener("increment", this.handleIncrement);
      window.addEventListener("gameEnded", this.handleGameEnded);

      if (this.isInitialized) {
        await this.fetchAllocation();
      }

      window.addEventListener("initializeGame", () => {
        if (this.gameState === 1 && this.isScriptLoaded) {
          this.checkGameInitialization();
        }
      });
    } catch (error) {
      console.error("Script loading error:", error);
    }
  },
  beforeUnmount() {
    window.removeEventListener("gameStarted", this.gameStartedHandler);
    window.removeEventListener("increment", this.handleIncrement);
    window.removeEventListener("gameEnded", this.handleGameEnded);
  },
  methods: {
    loadScript(src) {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.async = false;
        script.onload = resolve;
        script.onerror = () =>
          reject(new Error(`Failed to load script ${src}`));
        document.head.appendChild(script);
      });
    },
    checkGameInitialization() {
      if (
        this.isScriptLoaded &&
        this.isInitialized &&
        this.isGameVisible &&
        this.gamesLeft !== 0 &&
        this.gameState === 1
      ) {
        this.$nextTick(() => {
          this.initializeGameInstance();
        });
        return true;
      } else {
        //console.log("Not all conditions met for game initialization:");
        //console.log("Script loaded:", this.isScriptLoaded);
        //console.log("Initialized:", this.isInitialized);
        //console.log("Game visible:", this.isGameVisible);
        //console.log("Games left:", this.gamesLeft);
        //console.log("Game state:", this.gameState);
      }
    },
    toggleGameVisibility() {
      this.$store.commit("SET_GAME_VISIBILITY", !this.isGameVisible);
      //console.log("Toggled isGameVisible to:", this.$store.state.isGameVisible);
    },
    handleSignature(signature) {
      console.log("Received signature:", signature);
    },
    async handleGameStarted() {
      //console.log("handleGameStarted called", new Date().getTime());
      let token = localStorage.getItem("userToken");
      try {
        const response = await apiService.logGameStart({ token });
        this.$store.commit("SET_GAME_TOKEN", response.data.gameToken);
      } catch (error) {
        console.error("Error during game start:", error);
      }
    },
    async handleIncrement() {
      let token = localStorage.getItem("gameToken");
      try {
        const response = await apiService.logIncrement({ token });
        this.$store.commit("SET_GAME_TOKEN", response.data.gameToken);
      } catch (error) {
        console.error("Error during game score incrementation:", error);
      }
    },
    async handleGameEnded() {
      let token = localStorage.getItem("userToken");
      await apiService.logGameEnd({ token });
    },
    sendDataToGame(data) {
      const event = new CustomEvent("dataForGame", { detail: data });
      window.dispatchEvent(event);
    },
    async handleConnect() {
      try {
        await connect(); // Wait for connect() to complete
      } catch (error) {
        console.error("Error connecting:", error);
      }
    },
    async fetchAllocation() {
      try {
        await this.$store.dispatch("fetchAllocation");
      } catch (error) {
        console.error("Error fetching allocation:", error);
      }
    },
    async buyGames(amount) {
      console.log("Buy games clicked:", amount);
      await this.$store.dispatch("buyMoreGames", amount);
      this.showBuyGamesModal = false; // Close modal after attempting purchase
    },
    debugSetCountdown(seconds) {
      if (!DEBUG_MODE) return;

      if (this.gameState === 2) {
        this.$store.commit("UPDATE_REMAINING_TIME", seconds);
      } else if (this.gameState === 0 && this.isFundingComplete) {
        this.fundingCountdown = seconds;
      }
    },

    debugForceStateTransition() {
      if (!DEBUG_MODE) return;

      if (this.gameState === 2) {
        this.$store.commit("SET_IS_TRANSITIONING_TO_FUNDING_STATE", true);
        // Start polling with shorter intervals in debug mode
        this.startTransitionPolling();
      } else if (this.gameState === 0) {
        this.isFundingComplete = true;
        this.fundingCountdown = 5;
      }
    },

    startTransitionPolling() {
      const pollInterval = setInterval(
        async () => {
          const newGameState = await this.$store.dispatch("fetchGameState");

          if (newGameState === 0) {
            clearInterval(pollInterval);
            await Promise.all([
              this.$store.dispatch("fetchPrizePool"),
              this.$store.dispatch("fetchGamesLeft"),
            ]);
            this.$store.commit("SET_IS_TRANSITIONING_TO_FUNDING_STATE", false);
            window.location.reload();
          }
        },
        DEBUG_MODE ? 2000 : 10000
      ); // 2 seconds in debug mode

      // Safety timeout
      setTimeout(
        () => {
          clearInterval(pollInterval);
          this.$store.commit("SET_IS_TRANSITIONING_TO_FUNDING_STATE", false);
          window.location.reload();
        },
        DEBUG_MODE ? 10000 : 120000
      ); // 10 seconds in debug mode
    },

    debugSetGameState(state) {
      this.$store.commit("CLEAR_COUNTDOWN_INTERVAL");
      this.$store.commit("SET_GAME_STATE", state);

      if (state === 2) {
        // Claim Time - fetch actual remaining time
        this.$store.dispatch("fetchClaimEndTime").then((remainingTime) => {
          if (remainingTime > 0) {
            this.$store.dispatch("startCountdown", {
              type: "claimPeriod",
              duration: remainingTime,
            });
          }
        });
      } else if (state === 0 && Number(this.prizePool) >= 100) {
        this.$store.dispatch("startCountdown", {
          type: "fundingPeriod",
          duration: 120, // 2 minutes
        });
      }
    },

    debugSetTransitionState(type) {
      if (!DEBUG_MODE) return;

      if (type === "funding") {
        this.$store.commit("SET_IS_TRANSITIONING_TO_FUNDING_STATE", true);
        this.$store.commit("SET_TRANSITION_COUNTDOWN", TRANSITION_DURATION);
        this.$store.dispatch("startCountdown", {
          type: "toFundingTransition",
          duration: 120, // 2 minutes
        });
      } else if (type === "claim") {
        this.$store.commit("SET_IS_TRANSITIONING_TO_CLAIM_TIME", true);
        this.$store.commit("SET_TRANSITION_COUNTDOWN", TRANSITION_DURATION);
        this.$store.dispatch("startCountdown", {
          type: "toClaimTransition",
          duration: 120, // 2 minutes
        });
      }

      // Auto-reset after 5 seconds in debug mode
      setTimeout(() => {
        this.$store.commit("SET_IS_TRANSITIONING_TO_FUNDING_STATE", false);
        this.$store.commit("SET_IS_TRANSITIONING_TO_CLAIM_TIME", false);
      }, 5000);
    },

    debugSetPrizePool(amount) {
      this.$store.commit("SET_PRIZE_POOL", amount.toString());

      // If we're in funding state and prize pool is >= 10, start the countdown
      if (this.gameState === 0 && amount >= 100) {
        this.$store.commit("SET_TRANSITION_COUNTDOWN", TRANSITION_DURATION);
        this.$store.dispatch("startCountdown", {
          type: "fundingPeriod",
          duration: 120, // 2 minutes
        });
      }
    },

    debugToggleFundingComplete() {
      if (!DEBUG_MODE) return;
      this.$store.commit("SET_IS_FUNDING_COMPLETE", !this.isFundingComplete);

      if (!this.isFundingComplete) {
        // Reset countdown when toggling back to incomplete
        this.$store.commit("UPDATE_REMAINING_TIME", 0);
      } else {
        // Start countdown when marking as complete
        this.$store.commit("UPDATE_REMAINING_TIME", 60);
      }
    },

    debugSetAllocation() {
      if (this.debugAllocation === "") return;
      const amount = parseFloat(this.debugAllocation);
      if (isNaN(amount)) {
        this.toast.error("Please enter a valid number");
        return;
      }
      this.$store.commit("SET_ALLOCATION", amount.toString());
      this.toast.success(`Allocation set to ${amount}`);
    },

    debugSetGamesLeft() {
      if (this.debugGamesLeft === "") return;
      const amount = parseInt(this.debugGamesLeft);
      if (isNaN(amount)) {
        this.toast.error("Please enter a valid number");
        return;
      }
      this.$store.commit("SET_GAMES_LEFT", amount);
      this.toast.success(`Games left set to ${amount}`);
    },

    debugResetAll() {
      this.$store.commit("SET_GAME_STATE", 0);
      this.$store.commit("SET_GAMES_LEFT", 10);
      this.$store.commit("SET_PRIZE_POOL", "0");
      this.$store.commit("SET_ALLOCATION", "0");
      this.$store.commit("SET_HAS_CLAIMED", false);
      this.$store.commit("SET_IS_TRANSITIONING_TO_FUNDING_STATE", false);
      this.$store.commit("SET_IS_TRANSITIONING_TO_CLAIM_TIME", false);
      this.$store.commit("SET_LAST_ROUND_SCORE", 0);
      this.$store.commit("CLEAR_COUNTDOWN_INTERVAL");
      this.toast.success("All states reset to default");
    },

    getGameTemplate() {
      // Ensure variables are accessed correctly within the template string
      const gamesLeft = this.gamesLeft !== null ? this.gamesLeft : 'N/A';
      const highScore = this.highScore !== null ? this.highScore : 'N/A';

      return `
        <div id="gamescreen">
          <div id="sky" class="animated">
            <div id="flyarea">
              <div id="ceiling" class="animated"></div>
              <div id="player"></div>
              <div id="bigscore"></div>
              <div id="splash">
                <div class="flex flex-col items-center justify-center w-full max-w-lg mx-auto p-6">
                  <h2 class="text-2xl font-bold text-white mb-4 uppercase tracking-wider">
                    Get Ready
                  </h2>
                  <h3 class="text-base text-gray-300 text-center mb-8 uppercase tracking-wide">
                    Try to pass as many obstacles as possible to reach the Top 5
                  </h3>
                  <div class="w-full text-left mb-8 px-4">
                    <ul class="list-none space-y-3 text-gray-200 text-sm">
                      <li class="flex items-start">
                        <span class="text-orange-400 mr-2">></span>
                        <span>You have 10 games in total (${gamesLeft} left)</span>
                      </li>
                      <li class="flex items-start">
                        <span class="text-orange-400 mr-2">></span>
                        <span>Highest score can be submitted after game round is over (currently ${highScore})</span>
                      </li>
                      <li class="flex items-start">
                        <span class="text-orange-400 mr-2">></span>
                        <span>Top 5 players share the prize pool</span>
                      </li>
                    </ul>
                  </div>
                  <div class="w-full text-center">
                    <button 
                      class="inline-block px-6 py-2 bg-orange-500 text-white text-sm font-semibold rounded hover:bg-orange-600 transition-colors duration-200 uppercase tracking-wider"
                      @click="startGame"
                    >
                      Click here to start! LFG!
                    </button>
                  </div>
                </div>
              </div>
              <div id="scoreboard">
                <div id="currentscore"></div>
                <div id="highscore"></div>
                <div id="replay"></div>
              </div>
            </div>
          </div>
          <div id="land" class="animated">
            <div id="debug"></div>
          </div>
          <div id="playerbox" class="boundingbox"></div>
          <div id="pipebox" class="boundingbox"></div>
        </div>
      `;
    },
    initializeGameInstance() {
      if (this.isInitializing) {
        console.log("Already initializing game, skipping");
        return;
      }

      //console.log("Starting game initialization");
      this.isInitializing = true;

      try {
        const gameContainer = document.getElementById("gamecontainer");
        if (!gameContainer) throw new Error("Game container not found");

        // Clear any existing game state
        this.cleanupGameInstance();

        // Initialize new game
        //console.log("Setting up game template");
        gameContainer.innerHTML = this.getGameTemplate();

        // Make sure window.initGame exists before calling
        if (typeof window.initGame === "function") {
          //console.log("Calling window.initGame()");
          window.initGame();
        } else {
          throw new Error("Game initialization functions not found");
        }
      } catch (error) {
        console.error("Game initialization failed:", error);
        this.toast.error("Failed to initialize game");
      } finally {
        //console.log("Game initialization complete");
        this.isInitializing = false;
      }
    },
    startGame() {
      if (typeof window.startGame === "function") {
        window.startGame();
      }
    },
    cleanupGameInstance() {
      // Clear any existing intervals
      if (window.loopGameloop) clearInterval(window.loopGameloop);
      if (window.loopPipeloop) clearInterval(window.loopPipeloop);

      // Remove any existing game elements
      const gameContainer = document.getElementById("gamecontainer");
      if (gameContainer) gameContainer.innerHTML = "";
    },
    showGameSplash() {
      if (typeof window.showSplash === "function") {
        window.showSplash;
      } else {
        console.error("showSplash function not available");
      }
    },
    debugSetLastRoundScore() {
      if (this.debugLastRoundScore === "") return;
      const score = parseInt(this.debugLastRoundScore);
      if (isNaN(score)) {
        this.toast.error("Please enter a valid number");
        return;
      }
      this.$store.commit("SET_LAST_ROUND_SCORE", score);
      this.toast.success(`Last round score set to ${score}`);
    },
  },
  computed: {
    ...mapState({
      isTransitioningToGameState: (state) => state.isTransitioningToGameState,
      gameState: (state) => state.gameState,
      countdown: (state) => state.countdown,
      prizePool: (state) => state.prizePool,
      remainingTime: (state) => state.remainingTime,
      web3Address: (state) => state.web3Address,
      allocation: (state) => state.allocation,
      isTransitioningToFundingState: (state) =>
        state.isTransitioningToFundingState,
      isTransitioningToClaimTime: (state) => state.isTransitioningToClaimTime,
      hasClaimed: (state) => state.hasClaimed,
      lastRoundScore: (state) => state.lastRoundScore,
      flappyPrice: (state) => state.flappyPrice,
    }),
    remainingGameTime() {
      return this.$store.state.countdown?.type === "game"
        ? this.$store.state.countdown.value
        : 0;
    },
    scoreSubmitted() {
      return this.$store.state.scoreSubmitted;
    },
    getButtonTitle() {
      if (this.scoreSubmitted) {
        return "Improve your score";
      }
      if (this.remainingGameTime < 300) {
        return "Less than 5 minutes remaining in this round";
      }
      return "";
    },
    minPackagePrice() {
      if (!this.gamePackages || this.gamePackages.length === 0) return Infinity;
      return Math.min(...this.gamePackages.map(p => parseFloat(p.price)));
    },
    cannotAffordAnyPackage() {
      const currentBalance = parseFloat(this.$store.state.userBalance || '0');
      return currentBalance < this.minPackagePrice;
    },
  },
};
</script>

<style scoped>
.container {
  background-color: #1a202c transparent;
  /* padding: 0rem;  Removed invalid syntax and commented out as likely unused */
  border-radius: 0.5rem;
  width: 100%; 
  margin-left: auto;
  margin-right: auto;
}

.btn-primary {
  background-color: #3b82f6;
  &:hover {
    background-color: #2563eb;
  }
  color: white;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  transition: all 0.3s ease-in-out;
}

.claim-container {
  @apply relative overflow-hidden rounded-xl p-8;
  background: linear-gradient(
    135deg,
    rgba(17, 24, 39, 0.95) 0%,
    rgba(88, 28, 135, 0.8) 100%
  );
  box-shadow: 0 0 30px rgba(147, 51, 234, 0.2);
}

.claim-header {
  @apply text-4xl font-bold mb-8;
  background: linear-gradient(to right, #9333ea, #c084fc);
  -webkit-background-clip: text;
  color: transparent;
  text-shadow: 0 0 20px rgba(147, 51, 234, 0.3);
}

.score-display {
  @apply bg-gray-800/50 rounded-xl p-8;
  border: 1px solid rgba(147, 51, 234, 0.2);
  backdrop-filter: blur(8px);
  transform: translateZ(0);
  transition: all 0.3s ease;
}

.score-display:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(147, 51, 234, 0.2);
}

.prize-glow {
  animation: glow 2s ease-in-out infinite;
}

.claim-button {
  @apply bg-gradient-to-r from-purple-500 to-pink-500 text-white font-bold py-3 px-8 rounded-lg 
         transition-all duration-300 transform hover:scale-105 hover:shadow-lg;
}

.claimed-badge {
  @apply flex items-center justify-center space-x-2 bg-green-500/20 text-green-300 
         py-3 px-6 rounded-lg font-bold text-lg mt-4;
  animation: fadeIn 0.5s ease-out;
}

.motivation-box {
  @apply bg-gray-800/30 rounded-lg p-6 mt-6;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.with-particles {
  position: relative;
  overflow: hidden;
}

.with-particles::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  background: radial-gradient(
    circle,
    rgba(147, 51, 234, 0.1) 0%,
    transparent 70%
  );
  animation: rotate 20s linear infinite;
}

@keyframes glow {
  0%,
  100% {
    box-shadow: 0 0 20px rgba(147, 51, 234, 0.3);
  }
  50% {
    box-shadow: 0 0 30px rgba(147, 51, 234, 0.5);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-bounce-slow {
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(-5%);
  }
  50% {
    transform: translateY(0);
  }
}

.stats-container {
  @apply backdrop-blur-sm bg-gray-800/30 rounded-xl p-6;
  border: 1px solid rgba(147, 51, 234, 0.1);
}

.countdown-wrapper {
  @apply backdrop-blur-sm bg-gray-800/20 rounded-xl p-4;
  border: 1px solid rgba(147, 51, 234, 0.1);
}

.progress-container {
  @apply relative w-full h-4 bg-gray-700/50 rounded-full overflow-hidden;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.3);
}

.progress-bar {
  @apply h-full rounded-full transition-all duration-1000;
  background: linear-gradient(90deg, #9333ea 0%, #c084fc 50%, #9333ea 100%);
  box-shadow: 0 0 10px rgba(147, 51, 234, 0.5);
}

.timer-text {
  @apply absolute -top-6 right-0 text-sm font-medium;
  color: #c084fc;
  text-shadow: 0 0 8px rgba(147, 51, 234, 0.4);
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
}

.progress-bar-glow {
  box-shadow: 0 0 10px rgba(234, 179, 8, 0.5);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
}

@keyframes shimmer {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.3;
  }
}

.animate-shimmer {
  animation: shimmer 2s ease-in-out infinite;
}

@keyframes shimmer-horizontal {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.animate-shimmer-horizontal {
  animation: shimmer-horizontal 2s infinite;
}
</style>
