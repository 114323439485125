<!-- Header.vue -->
<template>
  <div>
    <div class="relative flex items-center justify-center">
      <h1 class="text-xl font-sans text-white text-center">
        CURRENT PRIZE POOL
      </h1>
    </div>
    <div class="flex justify-center mb-5">
      <div class="relative w-48 h-48">
        <svg viewBox="0 0 100 100" class="w-full h-full">
          <defs>
            <linearGradient id="prizeGradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" style="stop-color: #60a5fa" />
              <stop offset="50%" style="stop-color: #3b82f6" />
              <stop offset="100%" style="stop-color: #60a5fa" />
            </linearGradient>
            <filter id="prizeGlow">
              <!-- Blue glow -->
              <feGaussianBlur stdDeviation="2" result="blueBlur"/>
              <feFlood flood-color="#3b82f6" result="blueFlood"/>
              <feComposite in="blueFlood" in2="blueBlur" operator="in" result="blueGlow"/>
              
              <!-- Golden glow -->
              <feGaussianBlur stdDeviation="3" result="goldBlur"/>
              <feFlood flood-color="#fbbf24" result="goldFlood"/>
              <feComposite in="goldFlood" in2="goldBlur" operator="in" result="goldGlow"/>
              
              <!-- Combine glows with original -->
              <feMerge>
                <feMergeNode in="goldGlow"/>
                <feMergeNode in="blueGlow"/>
                <feMergeNode in="SourceGraphic"/>
              </feMerge>
            </filter>
            <!-- Animated gradient -->
            <linearGradient id="animatedGradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" style="stop-color: #60a5fa">
                <animate attributeName="stop-color" 
                  values="#60a5fa; #fbbf24; #3b82f6; #fbbf24; #60a5fa" 
                  dur="3s" repeatCount="indefinite"/>
              </stop>
              <stop offset="100%" style="stop-color: #3b82f6">
                <animate attributeName="stop-color" 
                  values="#3b82f6; #fbbf24; #60a5fa; #fbbf24; #3b82f6" 
                  dur="3s" repeatCount="indefinite"/>
              </stop>
            </linearGradient>
          </defs>
          <!-- Background circle with pulse -->
          <circle
            r="35"
            cx="50"
            cy="50"
            fill="transparent"
            stroke="#1F2937"
            stroke-width="8"
            class="opacity-30"
          />
          <!-- Animated circle -->
          <circle
            r="35"
            cx="50"
            cy="50"
            fill="transparent"
            stroke="url(#animatedGradient)"
            stroke-width="8"
            class="animate-pulse-slow transition-all duration-500"
            transform="rotate(-90 50 50)"
            filter="url(#prizeGlow)"
          />
        </svg>
        <!-- Centered text with glow -->
        <div class="absolute inset-0 flex flex-col items-center justify-center">
          <span class="text-base font-bold text-white prize-glow animate-pulse prize-amount">
            {{ formattedPrizePool }}
          </span>
          <span class="text-sm font-bold text-blue-400">$Flappy</span>
          <span class="usd-value">{{ prizePoolUsd }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

// Helper function for formatting large numbers
function formatLargeNumber(num) {
  if (num === null || num === undefined || isNaN(num) || num === 0) {
    return "0.00";
  }
  if (num < 10000) {
    return num.toFixed(2);
  } else if (num < 1000000) {
    return (num / 1000).toFixed(1) + 'k';
  } else {
    return (num / 1000000).toFixed(2) + 'M';
  }
}

export default {
  name: "RightButtonArea",
  computed: {
    ...mapState({
      rawPrizePool: (state) => state.prizePool,
      flappyPriceUsd: (state) => state.flappyPriceUsd || 0, // Assuming state.flappyPriceUsd exists, default to 0
    }),
    prizePoolValue() {
      const value = parseFloat(this.rawPrizePool);
      return isNaN(value) ? 0 : value;
    },
    formattedPrizePool() {
      return formatLargeNumber(this.prizePoolValue);
    },
    prizePoolUsd() {
      const usdValue = this.prizePoolValue * this.flappyPriceUsd;
      // Format USD value, maybe add commas for larger amounts
      return usdValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }
  },
};
</script>

<style scoped>
.prize-glow {
  text-shadow: 0 0 10px rgba(96, 165, 250, 0.7),
               0 0 20px rgba(251, 191, 36, 0.5),  /* Golden glow */
               0 0 30px rgba(37, 99, 235, 0.3);
}

/* Reduce font size slightly for the main number if needed */
.prize-amount {
  font-size: 1.1rem; /* Adjust as necessary */
  line-height: 1.3; /* Adjust line height */
}

/* Style for the USD value */
.usd-value {
  font-size: 0.75rem; /* Smaller font size */
  color: #9ca3af; /* Lighter text color */
  margin-top: 2px; /* Add some space */
}

@keyframes pulse-slow {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
}

.animate-pulse-slow {
  animation: pulse-slow 3s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

h1 {
  margin-bottom: 1.25rem;
}
</style>
