<template>
  <div
    class="leaderboard flex flex-col basis-full justify-center items-center"
  >
    <template v-if="isGameActive">
      <h2 class="text-2xl md:text-3xl font-bold uppercase mb-4 py-8 px-8">
        Game Rules & Mechanics
      </h2>
      <ul class="text-left max-w-2xl mx-auto space-y-4 py-8 px-8">
        <li class="flex items-start">
          <div>
            <strong>Eligibility to Participate:</strong>
            <p class="text-sm md:text-base font-medium">
              Players must hold a minimum of 200 $FLAPPY tokens to
              participate in gaming events.
            </p>
          </div>
        </li>
        <li class="flex items-start">
          <div>
            <strong>Starting a Game:</strong>
            <p class="text-sm md:text-base font-medium">
              Join a game round by donating $FLAPPY tokens, which grants
              10 attempts to achieve your highest score. You can rejoin every 24 hours or donate more flappy tokens to get more attempts during a round.
            </p>
          </div>
        </li>
        <li class="flex items-start">
          <div>
            <strong>Gameplay Mechanics:</strong>
            <p class="text-sm md:text-base font-medium">
              Tap the spacebar to fly and navigate Flappy around
              FUD-filled obstacles.
            </p>
          </div>
        </li>
        <li class="flex items-start">
          <div>
            <strong>Scoring and Ranking:</strong>
            <p class="text-sm md:text-base font-medium">
              After {{ totalGamesForRound }} attempts, submit your highest score. Scores are displayed
              on a real-time leaderboard during the {{ formatDuration(gameDuration) }} round.
            </p>
          </div>
        </li>
        <li class="flex items-start">
          <div>
            <strong>Selection of Top Players:</strong>
            <p class="text-sm md:text-base font-medium">
              Top players are determined based on their highest submitted scores
              at the end of each gaming event.
            </p>
          </div>
        </li>
        <li class="flex items-start">
          <div>
            <strong>Claiming Winnings:</strong>
            <p class="text-sm md:text-base font-medium">
              Winners can claim their ETH rewards at the end of the gaming
              event. Unclaimed winnings within the specified time are forfeited
              back to the Prize Fund wallet.
            </p>
          </div>
        </li>
      </ul>
    </template>
    <template v-else>
      <div class="credits-container w-full overflow-hidden">
        <h2 class="credits-title text-sm md:text-sm mb-4">
          Thanks to our Partners & Frens
        </h2>
        <div class="marquee">
          <span v-for="(credit, index) in credits" :key="index" class="mx-4 text-xl">
            {{ credit }}
          </span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: "GameRules",
  data() {
    return {
      credits: [
        "THRUSTER",
        "XY FINANCE",
        "BASELINE MARKETS",
        "W0LVES",
        "BASE",
        "BUGA WOZ ERE",
        "AND MANY M0RE",
      ]
    };
  },
  computed: {
    ...mapState({
      gameDuration: state => state.gameDuration,
      totalGamesForRound: state => state.totalGamesForRound
    }),
    formatDuration() {
      return (seconds) => {
        const minutes = Math.floor(seconds / 60);
        return `${minutes}-minute`;
      };
    },
    isGameActive() {
      return this.$store.state.gameState === 0
    }
  }
};
</script>

<style scoped>
.game-rules-container {
  @apply bg-gray-800 bg-opacity-20 rounded-lg shadow-lg transition-all duration-300;
}

.credits-container {
  @apply relative w-full py-8;
  background: linear-gradient(45deg, #000000, #1a1a1a);
  animation: cyberpunkBg 8s ease infinite;
  position: relative;
  overflow: hidden;
}

.credits-container::before,
.credits-container::after {
  content: '';
  position: absolute;
  top: -150%;
  left: -50%;
  width: 200%;
  height: 400%;
  background: repeating-linear-gradient(
    45deg,
    transparent 0%,
    rgba(45, 208, 245, 0.1) 1%,
    rgba(45, 208, 245, 0.1) 2%,
    transparent 3%
  );
  animation: scanlines 4s linear infinite;
}

.credits-container::after {
  animation-direction: reverse;
  animation-duration: 7s;
}

.marquee {
  @apply whitespace-nowrap relative z-10;
  animation: marquee 20s linear infinite;
  text-shadow: 
    0 0 2px #00ff00,
    0 0 8px #00ff00,
    0 0 15px #00ff00,
    0 0 22px #00ff00;
}

.marquee span {
  @apply font-bold;
  background: linear-gradient(90deg, #00ff00, #00ffff, #00ff00);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: textGlow 2s ease-in-out infinite alternate;
}

@keyframes cyberpunkBg {
  0%, 100% {
    background: linear-gradient(45deg, #000000, #1a1a1a);
  }
  50% {
    background: linear-gradient(45deg, #1a1a1a, #000000);
  }
}

@keyframes scanlines {
  0% {
    transform: translateX(-50%) rotate(45deg);
  }
  100% {
    transform: translateX(50%) rotate(45deg);
  }
}

@keyframes textGlow {
  0% {
    filter: brightness(1) drop-shadow(0 0 5px rgba(0, 255, 0, 0.7));
  }
  100% {
    filter: brightness(1.5) drop-shadow(0 0 15px rgba(0, 255, 0, 0.9));
  }
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.credits-title {
  @apply font-bold text-center relative z-10;
  background: linear-gradient(90deg, #00ffff, #00ff00, #00ffff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: 
    0 0 5px rgba(0, 255, 255, 0.5),
    0 0 10px rgba(0, 255, 255, 0.3),
    0 0 15px rgba(0, 255, 255, 0.2);
  animation: titlePulse 3s ease-in-out infinite;
}

@keyframes titlePulse {
  0%, 100% {
    opacity: 0.8;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
}
</style>
