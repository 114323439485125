<template>
  <div class="text-center">
    <div class="relative flex items-center justify-center">
      <h1 class="text-xl font-sans text-white p-5 text-center">
        Your Prize Share
      </h1>
    </div>
    <div v-if="isLoading" class="loading-container">
      <span class="loading-text">Loading</span>
    </div>
    <div v-else class="flex justify-center mb-5">
      <div class="relative w-48 h-48">
        <svg viewBox="0 0 100 100" class="w-full h-full">
          <defs>
            <linearGradient id="allocationGradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" style="stop-color: #22c55e" />
              <stop offset="50%" style="stop-color: #16a34a" />
              <stop offset="100%" style="stop-color: #22c55e" />
            </linearGradient>
            <linearGradient id="zeroGradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" style="stop-color: #ef4444" />
              <stop offset="50%" style="stop-color: #dc2626" />
              <stop offset="100%" style="stop-color: #ef4444" />
            </linearGradient>
            <filter id="allocationGlow">
              <feGaussianBlur stdDeviation="2" result="coloredBlur"/>
              <feMerge>
                <feMergeNode in="coloredBlur"/>
                <feMergeNode in="SourceGraphic"/>
              </feMerge>
            </filter>
          </defs>
          <!-- Background circle -->
          <circle
            r="35"
            cx="50"
            cy="50"
            fill="transparent"
            stroke="#1F2937"
            stroke-width="8"
            class="opacity-30"
          />
          <!-- Progress circle with gradient -->
          <circle
            r="35"
            cx="50"
            cy="50"
            fill="transparent"
            :stroke-dasharray="`${calculateProgress} ${fullCircle}`"
            :stroke="displayedAllocationValue > 0 ? 'url(#allocationGradient)' : 'url(#zeroGradient)'"
            stroke-width="8"
            class="transition-all duration-500"
            transform="rotate(-90 50 50)"
            filter="url(#allocationGlow)"
          />
        </svg>
        <!-- Centered text -->
        <div class="absolute inset-0 flex flex-col items-center justify-center text-center">
          <span class="text-base font-bold allocation-amount" :class="displayedAllocationValue > 0 ? 'text-green-400' : 'text-red-400'">
            {{ formattedAllocation }}
          </span>
          <span class="text-sm font-bold text-gray-400">$Flappy</span>
          <span class="usd-value">{{ allocationUsd }}</span>
          <span v-if="gameState == 1" class="text-xs text-gray-500 mt-1">(Estimated)</span>
        </div>
      </div>
    </div>
    <div v-if="gameState == 2 && displayedAllocationValue > 0" 
         class="flex justify-center mb-5">
      <button 
        @click="claimAllocation"
        class="bg-gradient-to-r from-green-500 via-green-400 to-green-500 
               text-white font-bold py-2 px-4 rounded-lg
               hover:opacity-90 transition-all duration-300
               animate-pulse">
        Claim
      </button>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import apiService from "../assets/js/apiService";
import { useToast } from "vue-toastification";

// Helper function for formatting large numbers (same as in RightButtonArea)
function formatLargeNumber(num) {
  if (num === null || num === undefined || isNaN(num) || num === 0) {
    return "0.00";
  }
  if (num < 10000) {
    return num.toFixed(2);
  } else if (num < 1000000) {
    return (num / 1000).toFixed(1) + 'k';
  } else {
    return (num / 1000000).toFixed(2) + 'M';
  }
}

export default {
  name: "AirdropAllocation",
  setup() {
    const toast = useToast();
    const store = useStore();
    const isLoading = ref(true);
    const estimatedAllocationRaw = ref(0); // Store the raw numeric value

    const gameState = computed(() => store.state.gameState);
    const score = computed(() => store.state.highestScore);
    const prizePool = computed(() => parseFloat(store.state.prizePool) || 0);
    const storeAllocation = computed(() => parseFloat(store.state.allocation) || 0);
    const flappyPriceUsd = computed(() => store.state.flappyPriceUsd || 0);

    const displayedAllocationValue = computed(() => {
      // Use the stored allocation if available (gameState != 1 or if it's > 0), otherwise use the fetched estimate
      const potentialAllocation = storeAllocation.value > 0 ? storeAllocation.value : estimatedAllocationRaw.value;
      return isNaN(potentialAllocation) ? 0 : potentialAllocation;
    });

    const formattedAllocation = computed(() => {
        return formatLargeNumber(displayedAllocationValue.value);
    });

    const allocationUsd = computed(() => {
      const usdValue = displayedAllocationValue.value * flappyPriceUsd.value;
      return usdValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    });

    const fullCircle = computed(() => 2 * Math.PI * 35);
    const calculateProgress = computed(() => {
      if (!displayedAllocationValue.value || !prizePool.value || prizePool.value === 0) return 0;
      const percentage = displayedAllocationValue.value / prizePool.value;
      return Math.min(percentage, 1) * fullCircle.value; // Cap at 100%
    });

    async function fetchEstimatedAllocation() {
      if (gameState.value !== 1 || storeAllocation.value > 0) {
          // Don't fetch if game ended or allocation is already set
          isLoading.value = false;
          return;
      }
      isLoading.value = true;
      try {
        const responsePromise = apiService.getCurrentPotentialAllocation();
        const response = await responsePromise;
        const percentageAllocation = parseFloat(response);

        if (isNaN(percentageAllocation)) {
          throw new Error("Invalid percentage allocation received");
        }

        const totalPrizePool = prizePool.value;
        if (isNaN(totalPrizePool)) {
          throw new Error("Invalid prize pool value");
        }

        const actualAllocation = (percentageAllocation / 100) * totalPrizePool;
        estimatedAllocationRaw.value = actualAllocation; // Store raw value
      } catch (error) {
        toast.error(`Error fetching estimated allocation: ${error.message}`);
        estimatedAllocationRaw.value = 0;
      } finally {
        isLoading.value = false;
      }
    }

    // Fetch initially and when score or prize pool changes during game state 1
    watch(
      [score, prizePool, gameState],
      ([, , newGameState], [, , ]) => { // Keep only newGameState, ignore others
        if (newGameState === 1 && storeAllocation.value <= 0) {
           // Only fetch/re-fetch if the game is active and no final allocation is set
           fetchEstimatedAllocation();
        } else if (newGameState !== 1 && estimatedAllocationRaw.value > 0) {
            // Reset estimated if game state changes from 1
            estimatedAllocationRaw.value = 0;
            isLoading.value = false; // Ensure loading is stopped
        } else {
            // If not fetching, ensure loading state is false
             isLoading.value = false;
        }
      },
      { immediate: true, deep: true } // immediate: run on load, deep: watch nested state if needed
    );

    // watch(prizePool, (newPrizePool, oldPrizePool) => {
    //   console.log(`Prize pool changed from ${oldPrizePool} to ${newPrizePool}`);
    //   if (newPrizePool !== null && newPrizePool !== undefined && gameState.value === 1) {
    //     fetchEstimatedAllocation();
    //   }
    // });

    return {
      isLoading,
      displayedAllocationValue, // Use the raw value for calculations
      formattedAllocation, // Use the formatted value for display
      allocationUsd, // Display USD value
      gameState,
      score,
      fullCircle,
      calculateProgress,
      // Removed claimAllocation as it wasn't defined in the original snippet
    };
  },
};
</script>

<style scoped>
.loading-container {
  display: inline-block;
  width: 100%;
  position: relative;
  padding: 5px;
  overflow: hidden;
  border-radius: 5px;
}

.loading-text {
  color: white;
  font-weight: bold;
  z-index: 1;
  position: relative;
}

.loading-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(90deg, transparent, red, transparent);
  animation: knightRiderAnimation 2s infinite linear;
}

@keyframes knightRiderAnimation {
  0% {
    left: -100%;
  }

  50% {
    left: 100%;
  }

  100% {
    left: -100%;
  }
}

.material-icons {
  vertical-align: baseline;
}

.glow-text {
  text-shadow: 0 0 10px rgba(251, 191, 36, 0.7);
}

/* Styles for the allocation amount */
.allocation-amount {
  font-size: 1.1rem; /* Adjust as necessary */
  line-height: 1.3; /* Adjust line height */
}

/* Style for the USD value (similar to RightButtonArea) */
.usd-value {
  font-size: 0.75rem; /* Smaller font size */
  color: #9ca3af; /* Lighter text color */
  margin-top: 2px; /* Add some space */
}
</style>
